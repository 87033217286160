import { defineStore } from 'pinia';
import { XMLHttpRequest } from 'xmlhttprequest';
// import $ from 'jquery';
// import ApiService from '~/services/ApiService';

export const usePillarStore = defineStore('pillar', {
  state () {
    return {
      searchResources: null,
      productPageData: null,
      selectedLang: 'English',
      baseUrl: 'https://api.swiftype.com/api/v1/public',
      productData: [],
      searchData: null,
      isSearch: false,
      searchEnterData: null,
      items: [],
      productLinks: [],
      blogLinks: [],
      pagNumbers: null,
      pagLimitNumbers: null,
      totalPages: null,
      languages: [
        { code: 'en', name: 'English', langName: 'English' },
        { code: 'fr', name: 'Français', langName: 'French' },
        { code: 'es', name: 'Español', langName: 'Spanish' },
        { code: 'ru', name: 'Русский', langName: 'Russian' },
        { code: 'pt', name: 'Português', langName: 'Portuguese' },
        { code: 'de', name: 'Deutsch', langName: 'German' },
        { code: 'hu', name: 'Magyar', langName: 'Hungarian' },
        { code: 'tr', name: 'Türkçe', langName: 'Turkish' },
        { code: 'ar', name: 'العربية', langName: 'Arabic' },
      ],
      pages: [
        {
          key: 'center_pivot',
          name: 'Center Pivot Irrigation',
        },
        {
          key: 'end_of_pivot',
          name: 'End of Pivot',
        },
        {
          key: 'pivot_corners',
          name: 'Pivot Corners',
        },
        {
          key: 'wheel_traction_reduction',
          name: 'Wheel Traction Reduction',
        },
        {
          key: 'lepa',
          name: 'LEPA',
        },
        {
          key: 'boom_irrigation',
          name: 'Boom Irrigation',
        },
        {
          key: 'linear_irrigation',
          name: 'Linear Irrigation',
        },
      ],
      selectedPage: 'Center Pivot Irrigation',
      navOpen: false,
      mobileMenuOpen: false,
    };
  },
  getters: {
    getNavOpen () {
      return this.navOpen;
    },
    getMobileMenuOpen () {
      return this.mobileMenuOpen;
    },
  },
  actions: {
    // TODO confirm can be removed since not loading from endpoint
    async getProductResources (searchText) {
      console.log(searchText, ' text');
      let response = await $fetch(
        'https://nelsonirrigation.com/res/getProductsForResources.asp?q=' +
          searchText
      );
      this.searchResources = response;
    },
    setIsSearch () {
      this.isSearch = this.isSearch ? false : true;
    },
    setNavOpen (bool) {
      this.navOpen = bool;
    },
    setMobileMenuOpen (bool) {
      this.mobileMenuOpen = bool;
      // if (bool) {
        this.navOpen = bool;
      // }
    },
    async getSearch (searchText) {
      var response = await $fetch(
        this.baseUrl +
          '/engines/suggest.json?q=' +
          searchText +
          '&engine_key=x2UxSubcEUv62CeT8XBs&fetch_fields%5BNelson%20Marketing%20Website%5D%5B%5D=title&fetch_fields%5BNelson%20Marketing%20Website%5D%5B%5D=body&_=1699048823705'
      );
      this.searchData = response.records.page;
      /* ApiService.getSearch(searchText)
        .then((response) => {
          this.searchData = [];
          this.searchData = response.data.records.page;
        })
        .catch((error) => {
          console.log(error);
        }) */
    },
    async getSearchEnter (searchText) {
      var response = await $fetch(
        this.baseUrl + '/installs/GoVFnJ3JpydSnFHdtQjj/search.json',
        {
          method: 'POST',
          body: searchText,
        }
      );

      this.searchEnterData = [];
      this.searchEnterData = response;
      const totalPages = this.searchEnterData.info.page.num_pages;
      this.totalPages = totalPages;
      if (this.pagNumbers === null && this.pagLimitNumbers === null) {
        this.pagNumbers = [];
        this.pagLimitNumbers = [];
        for (let i = 0; i < totalPages; i++) {
          this.pagNumbers.push({ number: i + 1, color: '#258BF8' });
          if (i + 1 <= 5) {
            if (i + 1 === 1) {
              this.pagLimitNumbers.push({ number: i + 1, color: 'black' });
            } else {
              this.pagLimitNumbers.push({ number: i + 1, color: '#258BF8' });
            }
          }
        }
      }
    },
    // async printJSON() {
    // const response = await fetch('MechanizedIrrigation.json');
    // const json = await response.json();
    // console.log(json);
    // },
    getSectionData () {
      var xhr = new XMLHttpRequest();
      xhr.open('GET', './MechanizedIrrigation.json', true);
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
          var data = JSON.parse(xhr.responseText);
          console.log(data);
        }
      };
      xhr.send();
      // this.printJSON()
      // fetch(file)
      //   // .then((res) => res.json())
      //   .then((data) => console.log(data));
      // let jsonData
      //   axios
      //      .get(file)
      //      .then(response => (jsonData = response))
      // console.log(jsonData);
      // $.ajax({
      //   type: 'GET',
      //   url: '../json/content/solutions/MechanizedIrrigation.json',
      //   contentType: 'application/json',
      //   async: true,
      //   success: function (data) {
      //     console.log(data);
      //   },
      //   error: function (error) {
      //     console.log('Error: ' + JSON.stringify(error));
      //   },
      // });
    },
  /*   getProductInfo (i18n) {
      this.productData = i18n.tm('products').map((entry) => {
        var entryKeys = Object.keys(entry);
        var entryValues = Object.values(entry);
        var blob = {};
        for (let i = 0; i < entryValues.length; i++) {
          if (
            entryValues[i] == null ||
            typeof entryValues[i] === 'boolean' ||
            typeof entryValues[i] === 'number'
          ) {
            blob[entryKeys[i]] = entryValues[i];
          } else if (entryValues[i].body != undefined) {
            blob[entryKeys[i]] = entryValues[i].body.static;
          } else {
            blob[entryKeys[i]] = entryValues[i].map((element) => {
              if (
                element == null ||
                typeof element === 'boolean' ||
                typeof element === 'number'
              ) {
                return element;
              } else if (element.body != undefined) {
                return element.body.static;
              }
              var elementKeys = Object.keys(element);
              var elementValues = Object.values(element);
              var subBlob = {};
              for (let j = 0; j < elementValues.length; j++) {
                if (
                  elementValues[j] == null ||
                  typeof elementValues[j] === 'boolean' ||
                  typeof elementValues[j] === 'number'
                ) {
                  subBlob[elementKeys[j]] = elementValues[j];
                } else if (elementValues[j].body != undefined) {
                  subBlob[elementKeys[j]] = elementValues[j].body.static;
                } else {
                  subBlob[elementKeys[j]] = elementValues[j].map((element) => {
                    return element.body.static;
                  });
                  // subBlob[elementKeys[j]] = null;
                }
              }
              return subBlob;
            });
          }
        }
        return blob;
      });
    }, */
  },
});
